<template>
    <section>
        <Suspense>
            <template #default>
                <Products /> 
            </template>
            <template #fallback>
                <SkeletonProducts />
            </template>
        </Suspense>
    </section>
</template>
<script setup>
import Products from './products.vue';
import SkeletonProducts from './skeletons/skeletonProducts.vue';
</script>