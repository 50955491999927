<template>
    <div class="flex">
        <div class="inner">
            <div v-text="$t('my_lists.favorites_already_selected.title')" />
            <Tooltip 
                :tooltip_message="$t('my_lists.tooltip_message_selected_products')"
                :size="tooltipSize"
            />
        </div>
        <GroupBy class="group-by" />
    </div>
</template>
<script setup>
    import { computed } from 'vue';
    import { useQuasar } from 'quasar';

    import GroupBy from './groupBy.vue';
    import Tooltip from '../../../../components/tooltip.vue';

    const $q = useQuasar();


    /* Define o tamanho do tooltip baseado no tamanho da tela */
    const tooltipSize = computed(() => $q.screen.lt.md ? '0.58rem' : '0.7rem')
</script>
<style scoped>
.flex {
    justify-content: space-between;
    align-items: center;
}

.flex > div {
    text-align: left;
    font-size: clamp(14px, 3.5vw, 24px);
    font-weight: 400;
    margin: 0px;
}

.flex .inner {
    display: flex;
    align-items: center;
}

@media ( max-width: 640px ) {
    .flex > div {
        margin-bottom: 3px;
    }

    .group-by {
        align-self: center;
    }
}
</style>