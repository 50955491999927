<template>
    <div class="product" :data-category-id="product.category_id">
        <q-linear-progress color="primary" indeterminate v-if="isLoading" />
        <a 
            :href="product.shared_link"
            target="_blank"
            role="button"
            class="product-content" 
            ref="refProductContent"
        >
            <div class="product-header">
                <a 
                    @click.stop.prevent="removeFavorite"
                    class="btn-remove-favorite" 
                    role="button"
                >
                    <q-icon name="favorite" :size="iconSize" />
                    <q-tooltip v-if="$q.screen.gt.sm">
                        {{ $t('my_lists.favorites_already_selected.product.remove_favorite') }}
                    </q-tooltip>
                </a>
                <a 
                    @click.stop.prevent="sharedProduct"
                    class="btn-share" 
                    role="button"
                >
                    <q-icon name="shared" :size="iconSize" />
                    <q-tooltip v-if="$q.screen.gt.sm">
                        {{ $t('my_lists.favorites_already_selected.product.share') }}
                    </q-tooltip>
                </a>

                <shareMenu 
                    ref="refShareMenu" 
                    :title="product.name"
                    :link="product.shared_link"
                />

                <q-img :src="product.full_url" loading="lazy" />
            </div>
            <div class="product-body">
                <small v-text="productCategory" />
                <h4 v-text="product.name" />
                <p 
                    class="price_ref"
                    v-text="formatPrice(product.price_ref)"
                />
            </div>
        </a>
    </div>
</template>
<script setup>
import { computed, defineProps, ref, defineAsyncComponent } from 'vue';
import { useQuasar } from 'quasar';
import { notifyInfo } from "@/shared/helpers/notify";
import { useActions, useGetters } from 'vuex-composition-helpers';
import { checkIfNativeSharingIsAvailable, nativeSharing } from "@/shared/helpers/share"
import { formatPrice } from '../../helpers/formatPrice';

// Components
const shareMenu = defineAsyncComponent(() => import('@/modules/main/components/shareMenu.vue'));
// Components

const props = defineProps({
    product: {
        type: Object,
        required: true
    }
})

const $q = useQuasar();

const isLoading = ref(false);
const refShareMenu = ref(null);
const refProductContent = ref(null)

const { removeProductsFromFavorite, fetchSelectedFavoritesList } = useActions({
    removeProductsFromFavorite: 'products/removeProductsFromFavorite',
    fetchSelectedFavoritesList: 'my_lists/fetchSelectedFavoritesList'
})

const { isDashboardCentralized } = useGetters({
    isDashboardCentralized: 'my_lists/isDashboardCentralized',
})


// ================== METHODS =========================

/* Remove o produto dos favoritos */
const removeFavorite = async () => {
    refProductContent.value.classList.add('flipnet-opacity')

    try {
        isLoading.value = true;

        await removeProductsFromFavorite({
          prod_id: props.product.product_id,
        })
                                
        notifyInfo("products.product_removed_from_favorite")

        await new Promise(resolve => setTimeout(resolve, 500))

        await fetchSelectedFavoritesList()
    } catch (error) {
        console.log('Houve um erro ao remover favoritos', error)
    } finally {
        isLoading.value = false;

        refProductContent.value.classList.remove('flipnet-opacity')
    }
}


const iconSize = computed(() => $q.screen.lt.md ? '1.1rem' : '1.3rem')


/**
 * Função para compartilhar um produto.
 * 
 * Verifica se o compartilhamento nativo está disponível no dispositivo. 
 * Se disponível, utiliza a funcionalidade de compartilhamento nativo. 
 * Caso contrário, abre um menu de compartilhamento customizado.
*/
const sharedProduct = async () => {
    if ( checkIfNativeSharingIsAvailable() ) {
        try {
            nativeSharing({
                title: props.product.name,
                text: `${props.product.name}: ${props.product.shared_link}` 
            })
        } catch (error) {
           notifyInfo('Houve um erro ao compartilhar o produto')
        }
    } else {
        refShareMenu.value.openMenu(event.currentTarget)
    }
}


/** Retorna o nome da 'Categoria'. Se estiver no DashCentralizado retorna o slug da company,
* caso contrário retorna o Nome da Categoria do Produto
*/
const productCategory = computed(() => {
    return isDashboardCentralized.value ? props.product.parent_slug : props.product.category_title   
})


</script>
<style scoped>
.product {
    border-radius: 6px;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.product > a {
    color: #000;
    text-decoration: none;
}

.product-header {
    background-color: #F5F6F8;
    position: relative;
}

.product-body {
    padding: 0px 0.55rem 0.2rem 0.55rem
}

.product-body .price_ref {
    margin: 0px 0px 5px 0px;
}

.btn-remove-favorite, 
.btn-share
{
    transition: transform 0.3s ease;
    z-index: 2
}

.btn-remove-favorite {
    position: absolute;
    left: 0.4rem;
    top: 0.3rem;
    z-index: 5;
}

.btn-remove-favorite:hover,
.btn-share:hover
{
    transform: scale(1.1)
}

.btn-remove-favorite :deep(.q-btn) {
    background-color: transparent
}

.btn-share {
    right: 0.4rem;
    position: absolute;
    top: 0.3rem;
}

a :deep(.q-icon) {
    color: var(--primary-custom-color) !important;
    justify-content: start !important;
}

.q-img {
    width: 120px;
    height: 130px;
    margin: 0px auto 8px auto;
    display: flex;
}

small {
    border: 1px solid #e2e8f0;
    border-radius: 40px;
    padding: 0.2rem 0.5rem;
    font-size: clamp(9px, 3.5vw, 10px);
}

h4 {
    font-size: clamp(10px, 3vw, 14px);
    font-weight: 400;
    line-height: 1.3;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    height: calc(2 * 1.2em);
    -webkit-box-orient: vertical;
    overflow: hidden;
}

p {
    font-weight: 500;
}

p {
    font-size: clamp(14px, 3.5vw, 14.5px);
}

p.sale-price {
    margin-top: 0.3rem;
}

.sale-price-exists {
    font-size: clamp(11px, 3.1vw, 13px) !important;
    text-decoration: line-through;
    color: #999;
    margin-top: 0rem;
    margin-bottom: 0rem
}

.q-btn {
    background-color: #2980b9;
    color: #fff;
    width: 100%;
    border-radius: 8px;
}

.q-btn span {
    text-transform: capitalize;
}

:deep(.q-icon.text-primary) {
    color: var(--primary-custom-color) !important;
}
 
@media ( min-width: 640px ) {
    .product {
        width: 200px;
    }

    .q-img {
        height: 150px;
    }
}
</style>