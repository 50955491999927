<template>
    <div class="action-buttons">        
        <div class="inner">
            <q-btn color="primary" :size="sizeButtons()" @click="addFavorites">
                <q-icon name="favorite" size="1rem" />
                <span class="q-ml-xs" v-text="$t('my_lists.action_buttons.add_to_favorites')" />
            </q-btn>
            <q-btn color="primary" :size="sizeButtons()" @click="externalLink">
                <q-icon name="open_in_new" size="1rem" />
                <span class="q-ml-xs" v-text="$t('my_lists.action_buttons.view_page')" />
            </q-btn>
        </div>
    </div>
</template>
<script setup>
import { useActions, useGetters } from 'vuex-composition-helpers';
import SelectProductsComponent from './components/popup-products/selectProductsComponent.vue'
import { useQuasar } from 'quasar';

const $q = useQuasar()

const { getPartnerLandingLink } = useGetters({
    getPartnerLandingLink: 'my_lists/getPartnerLandingLink',
})

const { 
    fetchPartnerLandingLink
} = useActions({
    fetchPartnerLandingLink: "my_lists/fetchPartnerLandingLink",
})


// ========== METHODS =========

/* Define o tamanho dos botões de acordo com a viewport */
const sizeButtons = () => $q.screen.lt.md ? 'sm' : 'md';


/* Abre o Dialog para selecionar os produtos Favoritos */
const addFavorites = () => {
    $q.dialog({
        component: SelectProductsComponent,
    })
}

/* Leva para a página de favoritos na loja do parceiro */
const externalLink = () =>  window.open(getPartnerLandingLink.value, '_blank');


try {
    const output = await fetchPartnerLandingLink()

    console.log('fetchPartnerLandingLink', output)
} catch (error) {
    console.error('Houve um erro', error)
}
</script>

<style scoped>
.action-buttons {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    gap: 12px;
}

.q-btn {
    border-radius: 100px;
    line-height: 0px !important;
    min-height: auto !important;
}

.q-btn :deep(.q-btn__content) {
    padding: 3px 0px !important;
}

.q-btn :deep(span),
.q-btn :deep(span .text-center)
{
    font-size: clamp(10px, 2.7vw, 14px); 
}

.q-btn:before {
    box-shadow: none !important;
}

.inner {
    display: flex;
    gap: 15px;
    width: 100%;
    order: 1;
}

.inner .q-btn {
    width: 50%;
}

.inner .q-btn:first-child {
    background: var(--primary-custom-color) !important;
}

.inner .q-btn:last-child {
    background: none !important;
    border: 1px solid #e2e8f0;
    transition: background-color 250ms ease;
    color: var(--primary-custom-color) !important;

    span,
    i {
        color: #121212;
    }
}

.q-btn:last-child:hover {
    background-color: #f8fafc !important;
}

:deep(.q-btn__content span) {
    text-transform: initial
}

@media ( min-width: 640px ) {
    .action-buttons {
        display: flex;
    }

    .q-btn :deep(.q-btn__content) {
        padding: 5px 0px !important;
    }

    .inner {
        order: 2;
        margin-left: auto;
        width: fit-content;
    }

    .inner .q-btn {
        width: 180px;
    }

    .q-btn-dropdown {
        order: 1;
    }
}
</style>