<template>
    <q-linear-progress 
        v-if="isLoadingInSelectedFavoritesList" 
        color="primary" indeterminate 
        class="q-mb-lg"
    />

        <div v-if="getSelectedFavoritesList?.length" class="products-container"
            :class="{ 'flipnet-opacity': isLoadingInSelectedFavoritesList }">

            <Toolbar />

            <div class="products">

                <div v-if="isDefault" class="default">
                    <Product
                        v-for="( product, index ) in getSelectedFavoritesList" 
                        :key="index" :product="product" 
                    />
                </div>

                <div v-else v-for="( category, index ) in getSelectedFavoritesList" :key="index">
                    <h4 class="category-title" v-text="category.title ?? category.slug" />
                    <div class="products-by-category">
                        <Product
                            v-for="( product, productIndex ) in category?.prods_parent ?? category.prods" 
                            :key="productIndex" :product="product" 
                        />
                    </div>
                </div>

            </div>

            <Pagination class="pagination" :resource="getPaginationFavoriteProductsSelected"
                :repository="handlePageChange" />
        </div>
        <div v-else class="no-products">
            Nenhum favorito encontrado! Comece a adicionar clicando em <strong>Adicionar favoritos</strong>
        </div>
</template>
<script setup>
import { useActions, useGetters } from 'vuex-composition-helpers';
import { computed, watch } from 'vue';

import Product from './product.vue';
import Pagination from "@/modules/main/components/pagination";
import Toolbar from './toolbar.vue';
// import SkeletonProducts from './skeletons/skeletonProducts.vue';

const { 
    fetchSelectedFavoritesList, setCurrentPageFavoriteProductsSelected,
    fechAllCompanies, setAllCompaniesInCentralized, setLoadingInSelectedFavoritesList
} = useActions({
    fetchSelectedFavoritesList: 'my_lists/fetchSelectedFavoritesList',
    setCurrentPageFavoriteProductsSelected: 'my_lists/setCurrentPageFavoriteProductsSelected',
    fechAllCompanies: 'my_lists/fechAllCompanies',
    setAllCompaniesInCentralized: 'my_lists/setAllCompaniesInCentralized',
    setLoadingInSelectedFavoritesList: 'my_lists/setLoadingInSelectedFavoritesList'
})

const {
    getSelectedFavoritesList, getPaginationFavoriteProductsSelected, getIsAddingProductToFavorites,
    isDashboardCentralized, isLoadingInSelectedFavoritesList
} = useGetters({
    getSelectedFavoritesList: 'my_lists/getSelectedFavoritesList',
    getPaginationFavoriteProductsSelected: 'my_lists/getPaginationFavoriteProductsSelected',
    getIsAddingProductToFavorites: 'my_lists/getIsAddingProductToFavorites',
    isDashboardCentralized: 'my_lists/isDashboardCentralized',
    isLoadingInSelectedFavoritesList: 'my_lists/isLoadingInSelectedFavoritesList'
})



/* Houve quando um ou mais produtos estão sendo adicionados aos favoritos 
* Quando newValue é 'true', algum produto foi adicionado aos favoritos
* Busca novamente os Favoritos selecionados ( searchAgainFavoriteProducts )
*/
watch(() => getIsAddingProductToFavorites.value, (newValue) => {
    if (newValue) {
        searchAgainFavoriteProducts()
    }
})


/**
 * Se existir algum item com product_id, significa que não há ordernação ativa ( por empresa ou categ ),
 * Caso contrário, a lista de produtos está ordernada por Empresas ou Categorias.
 * @returns {boolean} True caso seja uma lista simples, sem ordernação. False caso seja uma lista ordenada ( Por empresa ou categ )
 */
const isDefault = computed(() => {
    return getSelectedFavoritesList.value.every(product => product?.product_id)
})


// ================== METHODS =========================

/* Executado na mudança de página ( define a página clicada e busca novamente os favoritos selecionados ) */
const handlePageChange = async (currentPage) => {
    setCurrentPageFavoriteProductsSelected(currentPage.page)

    searchAgainFavoriteProducts()
}


/* Busca novamente a lista de favoritos selecionados */
const searchAgainFavoriteProducts = async () => {
    setLoadingInSelectedFavoritesList(true)

    scrollToTop()

    try {
        await fetchSelectedFavoritesList()
    } catch (error) {
        console.error('Erro ao busca produto', error)
    } finally {
        setLoadingInSelectedFavoritesList(false)
    }
}


/* Se estiver no Dash Centralizado, realiza as ações relacionadas */
async function executeCentralizedDashboardActions() {
    try {
        const output = await fechAllCompanies();

        if (!Array.isArray(output)) {
            throw new Error('Houve um erro: getAllCompanies nao retornou um array');
        }

        setAllCompaniesInCentralized(output)
    } catch (error) {
        console.log('Houve um erro ao executar as ações do Dash Centralizado', error)
    }
}


const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

// ================== METHODS =========================


await new Promise(resolve => setTimeout(resolve, 500))

try {    
    if ( isDashboardCentralized.value ) {
        await executeCentralizedDashboardActions()
    }

    await fetchSelectedFavoritesList()
} catch (error) {
    console.log('Houve um erro ao buscar a lista de favoritos', error)
}
</script>
<style scoped>
.products-container {
    border: 1px solid #e2e8f0;
    border-radius: 1rem;
    padding: 15px;
}

.products .default {
    margin-top: 40px;
}

.products .default,
.products .products-by-category 
{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    justify-content: start;
}

.category-title {
    display: flex;
    align-items: center;
    text-align: center;
    color: #000;
    font-weight: 300 !important;
    font-size: clamp(16px, 4vw, 22px) !important;
    margin: 35px 0px 35px 0px;
}

.category-title:before,
.category-title:after
{
    content: "";
    flex: 1;
    border-bottom: 1px solid #e2e8f0;
}

.category-title:before {
    margin-right: 14px;
}

.category-title:after {
    margin-left: 14px;
}


.no-products {
    text-align: center;
    font-size: clamp(12px, 3.5vw, 16px);
    margin-bottom: 1rem
}

@media (min-width: 768px) {
    .products-container {
        padding: 30px;
    }

    .products .default,
    .products .products-by-category
    {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (min-width: 1024px) {
    .products .default,
    .products .products-by-category 
    {
        grid-template-columns: repeat(5, 1fr);
    }
}
</style>