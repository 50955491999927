<template>
    <div class="products-container">
        <div class="action-buttons">
            <q-skeleton type="text" />
            <q-skeleton />
        </div>
        <div class="products">
            <q-skeleton class="product" v-for="index in 10" :key="index" />
        </div>
    </div>
</template>
<style scoped>
.products-container {
    padding: 15px;
}

.action-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
}

.action-buttons .q-skeleton:first-child {
    width: 50%;
    height: 40px;
}

.action-buttons .q-skeleton:last-child {
   display: none;
}

.products {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    justify-content: start;
}

.product {
    border-radius: 6px;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    height: 277px;
}

@media (min-width: 640px) {
    .product {
        width: 200px;
    }

    .products {
        grid-template-columns: repeat(4, 1fr);
    }

    .action-buttons .q-skeleton:first-child {
        width: 250px;
        height: 50px;
    }

    .action-buttons .q-skeleton:last-child {
        width: 150px;
        height: 30px;
        display: block;
        border-radius: 100px;
    }
}

@media (min-width: 1024px) {
    .products {
        grid-template-columns: repeat(5, 1fr);
    }
}
</style>