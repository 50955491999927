<template>
    <div class="q-gutter-y-md container">
        <q-card>
            <Navbar />
        </q-card>
        <q-card>
            <q-tabs v-model="tab" dense class="text-grey" active-color="primary" indicator-color="primary" align="left"
                narrow-indicator>
                <q-tab name="favorites" label="Favoritos" class="q-pt-md" />
            </q-tabs>

            <q-separator />

            <q-tab-panels v-model="tab" animated>
                <q-tab-panel name="favorites">

                    <Suspense>
                        <template #default>
                            <ActionButtons class="q-mt-xs" />
                        </template>
                        <template #fallback>
                            <SkeletonActionButtons />
                        </template>
                    </Suspense>

                    <SelectedFavoritesList class="favorites-list" />
                </q-tab-panel>
            </q-tab-panels>
        </q-card>
    </div>
</template>
<script setup>
import { ref, watch } from "vue";
import { useGetters, useActions } from "vuex-composition-helpers";

// COMPONENTS
import SelectedFavoritesList from "./components/favorites-already-selected/SelectedFavoritesList.vue";
import Navbar from './navbar.vue';
import ActionButtons from "./actionButtons.vue";
import SkeletonActionButtons from "./skeletons/skeletonActionButtons.vue";
// COMPONENTS

const tab = ref('favorites');

const { partner, influencer } = useGetters({
    partner: 'partner/getPartner',
    influencer: 'account/getPartnerAccount',
})

const {
    setParentsIDs, setInfluencerID, setDashboardCentralized, setRequestConfigSelectedFavoritesList
} = useActions({
    setParentsIDs: 'my_lists/setParentsIDs',
    setInfluencerID: 'my_lists/setInfluencerID',
    setDashboardCentralized: 'my_lists/setDashboardCentralized',
    setRequestConfigSelectedFavoritesList: 'my_lists/setRequestConfigSelectedFavoritesList'
})

watch(partner, async (newValue) => {
    if (newValue?.id) {
        setParentsIDs([partner.value?.id])
        setInfluencerID([influencer.value?.id])

        if (partner.value?.slug === "flipnet") {
            setDashboardCentralized(true)

            /* No dash centralizado, exibe inicialmente a lista de todos os Produtos Selecionados */
            setRequestConfigSelectedFavoritesList('company') 
        } else {

            /* No dash da empresa, exibe inicialmente os produtos ordenados por Categorias */
            setRequestConfigSelectedFavoritesList('category')
        }
    }
}, { immediate: true })

</script>
<style scoped>
.container {
    display: flex;
    flex-direction: column;
}

.container>.q-btn {
    align-self: end;
    background-color: #1F2A37 !important;
    color: hsl(0, 0%, 100%);
    text-transform: initial;
}

.q-card {
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
}

.q-card:first-child {
    padding: 8px 0px 8px 15px;
}

.q-tab-panels :deep(.q-tab-panel) {
    padding: 10px
}

.favorites-list {
    margin-top: 30px;
}

:deep(.text-info) {
    display: none
}

:deep(.all-products) {
    padding-top: 0px
}

:deep(.text-primary) {
    color: var(--primary-custom-color) !important
}

@media ( min-width: 640px ) {
    .q-tab-panels :deep(.q-tab-panel) {
        padding: 16px
    }

    .q-card:first-child {
        padding: 15px 15px 15px 16px;
    }    
}
</style>