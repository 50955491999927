<template>
    <div class="navbar">
        <div class="navbar-top">
            <h1 v-text="$t('my_lists.navbar.title')" />
            <Tooltip 
                class="tooltip"
                :tooltip_message="$t('my_lists.tooltip_message')" 
                size="0.8rem"
            />
        </div>
        <div class="navbar-center">
        </div>
    </div>
</template>
<script setup>
// Components
import Tooltip from '@/modules/main/components/tooltip.vue';
//Components
</script>

<style scoped>
.navbar-top {
    display: flex;
    align-items: center;
}

h1 {
    font-size: clamp(16px, 4vw, 25px);
    line-height: 0;
    text-transform: initial;
    font-weight: 400;
}

.tooltip {
    position: relative;
    top: 0.1rem;
}

.navbar-center {
    margin-top: 5px;
    position: relative;
    display: flex;
    display: none !important;
    justify-content: space-between;
}

.navbar-center p {
    font-size: clamp(12px, 4vw, 15px);
    margin: 13px 0px;
}

.navbar-bottom {
    margin-top: 28px;
}

@media ( min-width: 599.99px ) {
    .navbar-center p {
        max-width: 800px;
    }
}
</style>