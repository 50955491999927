/**
 * Verifica se o recurso de compartilhamento nativo está disponível no navegador.
 *
 * @returns {boolean} - Retorna `true` se o compartilhamento nativo estiver disponível, ou `false` se não estiver.
 *
 * Exemplo:
 * ```
 * const isNativeSharingAvailable = checkIfNativeSharingIsAvailable();
 * if (isNativeSharingAvailable) {
 *   console.log('O compartilhamento nativo está disponível.');
 * } else {
 *   console.log('O compartilhamento nativo não está disponível.');
 * }
 * ```
*/
export const checkIfNativeSharingIsAvailable = () => {
    return !!navigator.share;
}



/**
 * Compartilha conteúdo nativamente usando a API de compartilhamento do navegador.
 *
 * @param {IShare} options - Um objeto contendo as opções de compartilhamento.
 * @param {string} options.title - O título do conteúdo a ser compartilhado.
 * @param {string} options.text - O texto do conteúdo a ser compartilhado.
 * @param {File | null} [options.files] - Um arquivo opcional a ser compartilhado. Se fornecido, será incluído na lista de arquivos para compartilhar.
 * 
 * @throws {Error} Se houver um erro durante o processo de compartilhamento.
 *
 * Exemplo:
 * ```
 * nativeSharing({
 *   title: 'Check this out!',
 *   text: 'This is really cool!',
 *   files: selectedFile
 * }).catch(error => {
 *   console.error('Error sharing:', error);
 * });
 * ```
*/
export const nativeSharing = async ({ title, text, files }) => {
    await navigator.share({
        title: title,
        text: text,
        files: files ? [files] : undefined
    })
}
