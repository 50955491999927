<template>
    <div class="filter">
        <div v-for="item in 4" class="column filter-child" :key="item">
            <q-skeleton animation="wave" />
        </div>
    </div>
</template>
<style scoped>
.filter {
    display: flex;
    flex-direction: column;
    gap: 17px;
}

/* border pode ter a cor rgba(0, 0, 0, 0.24) ( mais forte )  */

.filter-child {
    border-radius: 8px;
    padding: 15px;
    border: 1px solid #e2e8f0;
}

.filter-child strong {
    margin-bottom: 5px;
}

.filter-child .q-skeleton {
    height: 32px;
}

.brands {
    display: grid;
    gap: 7px;
}
</style>