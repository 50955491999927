<template>
    <section class="section-products">
        <div class="section-inner">
            <div class="toolbar">
               <q-skeleton type="text" />
               <div class="toolbar-inner">   
                <q-skeleton type="text" />
                <q-skeleton type="text" />
               </div>
            </div>
        </div>

        <div class="products-container">
            <div >
                <div class="products">
                    <q-skeleton class="product" v-for="(item) in 12" :key="item" />
                </div>
            </div>
        </div>
    </section>
</template>
<style scoped>
.section-products {
    -webkit-overflow-scrolling: touch !important;
    padding-bottom: 50px;
}

.products-container {
    -webkit-overflow-scrolling: touch !important;
    margin-top: 20px;
}

.product {
    border-radius: 6px;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    height: 300px;
}

.section-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 7px
}

.toolbar {
   display: flex;
   gap: 15px;
   justify-content: space-between;
   width: 100%;
}

.toolbar > .q-skeleton {
    align-self: baseline;
    width: 20%;
    height: 30px
}

.toolbar-inner {
    display: grid;
    align-items: center;
    width: 50%;
    margin-left: auto;
}

.toolbar-inner .q-skeleton:first-child {
    border-radius: 12px;
}

.toolbar-inner .q-skeleton {
    margin-left: auto;
    height: 40px;
    width: 50%;
    max-width: 170px
}

.toolbar-inner > div :deep(button){
    float: right;
}

.toolbar-inner :deep(.q-checkbox__inner) {
    margin-left: auto;
}

h6 {
    margin: 0px;
    font-size: clamp(14px, 3.5vw, 16px);
    text-align: left;
}

.products {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    justify-content: start;
}

@media ( min-width: 640px ) {
    .toolbar > .q-skeleton {
        display: none;
    }

    .toolbar-inner {
        display: flex;
        gap: 10px;
    }

    .toolbar-inner > div:first-child {
        order: 2
    }

    .toolbar-inner > div:last-child {
        order: 1
    }
}

@media (min-width: 1024px) {
    .section-products {
        padding-right: 7px;
    }

    .products {
        grid-template-columns: repeat(3, 1fr);
        gap: 3rem;
    }
}
</style>