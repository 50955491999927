<template>
    <q-btn-dropdown flat no-caps>
        <template v-slot:label>
            <div class="row items-center no-wrap">
                <div class="text-center" v-text="groupByText" />
            </div>
        </template>

        <q-list>
            <q-item>
                <p v-text="$t('my_lists.favorites_already_selected.group_by.title')" />
            </q-item>
            <div class="divider" />
            <q-item 
                @click="groupBy('category')"
                :disable="getRequestConfigInCentralized === 'category'"
                clickable v-close-popup
            >
                <q-item-section>
                    <q-item-label 
                        v-text="$t('my_lists.favorites_already_selected.group_by.category')" 
                    />
                </q-item-section>
            </q-item>

            <q-item 
                v-if="isDashboardCentralized"
                @click="groupBy('company')"
                :disable="getRequestConfigInCentralized === 'company'"
                clickable v-close-popup
            >
                <q-item-section>
                    <q-item-label 
                        v-text="$t('my_lists.favorites_already_selected.group_by.company')" 
                    />
                </q-item-section>
            </q-item>
            <q-item 
                @click="groupBy('all_products')"
                :disable="getRequestConfigInCentralized === 'all_products'"
                clickable v-close-popup
            >
                <q-item-section>
                    <q-item-label 
                        v-text="$t('my_lists.favorites_already_selected.group_by.product')" 
                    />
                </q-item-section>
            </q-item>
        </q-list>
    </q-btn-dropdown>
</template>
<script setup>
import { computed } from 'vue';
import { useActions, useGetters } from 'vuex-composition-helpers';

const { getRequestConfigInCentralized, isDashboardCentralized } = useGetters({
    getRequestConfigInCentralized: 'my_lists/getRequestConfigInCentralized',
    isDashboardCentralized: 'my_lists/isDashboardCentralized'
})

const { 
    fetchSelectedFavoritesList, setRequestConfigSelectedFavoritesList,
    setLoadingInSelectedFavoritesList
} = useActions({
    fetchSelectedFavoritesList: 'my_lists/fetchSelectedFavoritesList',
    setRequestConfigSelectedFavoritesList: 'my_lists/setRequestConfigSelectedFavoritesList',
    setLoadingInSelectedFavoritesList: 'my_lists/setLoadingInSelectedFavoritesList'
})

const groupBy = async (type) => {

    setRequestConfigSelectedFavoritesList(type)

    setLoadingInSelectedFavoritesList(true)

    try {
        await fetchSelectedFavoritesList()
    } catch (error) {
        console.log('Houve um erro ao buscar os produtos favoritos', error)
    } finally {
        setLoadingInSelectedFavoritesList(false)
    }
}

const groupByText = computed(() => {
    return getRequestConfigInCentralized.value == "category" ? 'Por categoria' :
    getRequestConfigInCentralized.value == "company" ? 'Por empresa' :
    getRequestConfigInCentralized.value == "all_products" ? 'Por produtos' :
    'Agrupar'
})
</script>
<style scoped>


/* q-btn-dropdown pode ter um border com a cor: rgba(7, 6, 6, 0.24)*/

.q-btn-dropdown {
    border: 1px solid #e2e8f0;
    order: 2;
    border-radius: 100px;
    width: fit-content;
    align-self: end;
    line-height: 0px !important;
    font-weight: 400;
    min-height: auto !important;
    padding: 2px 15px !important;
}

.q-btn-dropdown:hover {
    background-color: #f8fafc
}

.q-btn-dropdown :deep(span),
.q-btn-dropdown :deep(span .text-center)
{
    font-size: clamp(10px, 2.7vw, 14px); 
}

.q-item :deep(.q-item__label),
.q-item p
{
    font-size: clamp(12px, 2.7vw, 14px); 
}

.q-item :deep(.q-item__label) {
    font-weight: 400;
}

.q-item:first-child {
    min-height: 0px;
    padding: 0px 16px;
}

p {
    margin: 0.7rem 0px 0.45rem 0px;
    font-weight: 500;
}

.divider {
    margin: 5px 0px;
    width: 100%;
    height: 1px;
    background: #e2e8f0;
}

@media ( min-width: 640px ) {
    .q-btn-dropdown :deep(.q-btn__content) {
        padding: 3px 7px !important;
    }    
}
</style>