<template>
    <div class="action-buttons">
        <q-btn 
            v-if="showButtonClearFilters" 
            @click="clearFilters" 
            class="button-clear-filters" 
            outline
            label="Limpar filtros" 
        />

        <q-btn 
            @click="filter" 
            class="button-filtered" 
            color="primary" border 
            label="Filtrar" 
        />
    </div>
</template>
<script setup>
import { useActions, useGetters } from 'vuex-composition-helpers';
import { useQuasar } from 'quasar';
import { ref, defineProps } from 'vue';

const props = defineProps({
    clearActiveCheckboxes: {
        type: Function,
        required: true
    }
})

const $q = useQuasar();

const {  
    isDashboardCentralized, getCompaniesIDsInCentralized, 
    getCompaniesFilterInCentralized, getFilters,
    hasFiltersInPayload
 } = useGetters({
    isDashboardCentralized: 'my_lists/isDashboardCentralized',
    getCompaniesIDsInCentralized: 'my_lists/getCompaniesIDsInCentralized',
    getCompaniesFilterInCentralized: 'my_lists/getCompaniesFilterInCentralized',
    getFilters: 'my_lists/getFilters',
    hasFiltersInPayload: 'my_lists/hasFiltersInPayload'
})

const {
    fetchProductsAndFilters, setLoading, setOrderBy,
    setDrawer, setSelectedAllProducts, resetFiltersPayloads,
    setFetchingFilters, setParentsIDs, setFilters
} = useActions({
    fetchProductsAndFilters: 'my_lists/fetchProductsAndFilters',
    setLoading: 'my_lists/setLoading',
    setOrderBy: 'my_lists/setOrderBy',
    setDrawer: 'my_lists/setDrawer',
    setSelectedAllProducts: 'my_lists/setSelectedAllProducts',
    resetFiltersPayloads: 'my_lists/resetFiltersPayloads',
    setFetchingFilters: 'my_lists/setFetchingFilters',
    setParentsIDs: 'my_lists/setParentsIDs',
    setFilters: 'my_lists/setFilters'
})


const showButtonClearFilters = ref(false);

// Função disparada quando clica no botão 'Filtrar'
const filter = async () => {
    
    if ($q.screen.lt.md) { // Em telas menores, fecha o menu após clicar em filtrar
        setDrawer(false)
    }

    setOrderBy({})
    setSelectedAllProducts([])
    setLoading(true)

    try {
        await fetchProductsAndFilters()
    } catch (error) {
        console.error('Erro ao buscar produtos', error)
    } finally {
        setLoading(false)
        
        if ( hasFiltersInPayload.value ) showButtonClearFilters.value = true
        else showButtonClearFilters.value = false
    }
}

// Função disparada quando clica em 'Limpar Filtros'
const clearFilters = async () => {

    resetFiltersPayloads()
    setFetchingFilters(true)

    if ( isDashboardCentralized.value ) { // Verifica se está no dash centralizado
        setSelectedCompaniesInPayload()
        setCompaniesFilter()
    }

    try {
        setLoading(true)

        await fetchProductsAndFilters()

        setDrawer(false) // Após limpar os filtros, fecha o menu lateral no mobile

        props.clearActiveCheckboxes()

    } finally {

        showButtonClearFilters.value = false;
        setFetchingFilters(false)
        setLoading(false)
    }
}


const setSelectedCompaniesInPayload = () => {
    setParentsIDs([ ...getCompaniesIDsInCentralized.value ])
}

const setCompaniesFilter = () => {

    const filters = Object.assign({}, getFilters.value, getCompaniesFilterInCentralized.value)

    setFilters(filters)
}
</script>
<style scoped>
.action-buttons {
    position: sticky;
    display: grid;
    padding: 1rem 0px;
    background-color: #fff;
    gap: 12px;
    left: 0;
    width: 100%;
    z-index: 2;
    bottom: 0px;
}

.button-clear-filters {
    transition: background-color 250ms ease;
}

.button-clear-filters :deep(span) {
    color: var(--primary-custom-color)
}

.button-clear-filters::before {
    border: 1px solid var(--primary-custom-color)
}

.button-clear-filters:hover {
    background-color: #f7f6f6 !important;
  
}

.button-filtered {
    background: var(--primary-custom-color) !important;
}

@media ( min-width: 768px ) {
    .action-buttons {
        bottom: 1rem
    }
}

</style>